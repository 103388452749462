import { UserSettings } from 'src/app/modules/workspace/component-pieces/user/models/user_setting.interface';

export const UserSettingsVariables: UserSettings = {
  documentTypes: {
    invoices: {
      fieldName: 'Invoices',
      label: 'Invoices',
      value: 'invoice',
      isRequired: false,
      isChecked: false,
    },
    receipts: {
      fieldName: 'Receipts',
      label: 'Receipts',
      value: 'receipt',
      isRequired: false,
      isChecked: false,
    },
    purchaseorders: {
      fieldName: 'Purchase Orders',
      label: 'Purchase Orders',
      value: 'purchaseorder',
      isRequired: false,
      isChecked: false,
    },
    rfq: {
      fieldName: 'RFQ',
      label: 'RFQ',
      value: 'rfq',
      isRequired: false,
      isChecked: false,
    },
    nach: {
      fieldName: 'NACH',
      label: 'NACH',
      value: 'nach',
      isRequired: false,
      isChecked: false,
    },
    insurances: {
      fieldName: 'Insurances',
      label: 'Insurances',
      value: 'insurances',
      isRequired: false,
      isChecked: false,
    },
    AddressDocument: {
      fieldName: 'Address Documents',
      label: 'Address Documents',
      value: 'AddressDocument',
      isRequired: false,
      isChecked: false,
    },
    idcards: {
      fieldName: 'Id Cards',
      label: 'Id Cards',
      value: 'idcards',
      isRequired: false,
      isChecked: false,
    },
    marksheets: {
      fieldName: 'Marksheets',
      label: 'Marksheets',
      value: 'marksheets',
      isRequired: false,
      isChecked: false,
    },
    BolDocument: {
      fieldName: 'Bill of Lading',
      label: 'BolDocument',
      value: 'bolDocument',
      isRequired: false,
      isChecked: false,
    },
    PackingList: {
      fieldName: 'Packing List',
      label: 'PackingList',
      value: 'PackingList',
      isRequired: false,
      isChecked: false,
    },
    BillofExchange: {
      fieldName: 'Bill of Exchange',
      label: 'BillofExchange',
      value: 'BillofExchange',
      isRequired: false,
      isChecked: false,
    },
    BADocument: {
      fieldName: 'Beneficiary Attestation',
      label: 'BADocument',
      value: 'BADocument',
      isRequired: false,
      isChecked: false,
    },
    tradeFinance: {
      fieldName: 'Trade Finance',
      label: 'tradeFinance',
      value: 'tradeFinance',
      isRequired: false,
      isChecked: false,
    },
    TIFDocument: {
      fieldName: 'TIF Document',
      label: 'TIFDocument',
      value: 'TIFDocument',
      isRequired: false,
      isChecked: false,
    },
    VSCCDocument: {
      fieldName: 'VSCC Document',
      label: 'VSCCDocument',
      value: 'VSCCDocument',
      isRequired: false,
      isChecked: false,
    },
    OnboardingForm: {
      fieldName: 'Onboarding Document',
      label: 'OnboardingForm',
      value: 'OnboardingForm',
      isRequired: false,
      isChecked: false,
    },
    AirwayBill: {
      fieldName: 'Airway Bill',
      label: 'AirwayBill',
      value: 'AirwayBill',
      isRequired: false,
      isChecked: false,
    },
    dealerquotation: {
      fieldName: 'Dealer Quotations',
      label: 'dealerquotation',
      value: 'dealerquotation',
      isRequired: false,
      isChecked: false,
    },
    LoanNotices: {
      fieldName: 'Loan Notices',
      label: 'Loan Notices',
      value: 'LoanNotices',
      isRequired: false,
      isChecked: false,
    },
    Property_Appraisal: {
      fieldName: 'Property Appraisal Document',
      label: 'Property_Appraisal',
      value: 'Property_Appraisal',
      isRequired: false,
      isChecked: false,
    },
    LoanApplication: {
      fieldName: 'Loan Application',
      label: 'LoanApplication',
      value: 'LoanApplication',
      isRequired: false,
      isChecked: false,
    },
    depositoryorders: {
      fieldName: 'Depository Orders',
      label: 'DepositoryOrders',
      value: 'DepositoryOrders',
      isRequired: false,
      isChecked: false,
    },
    importchecklist: {
      fieldName: 'Import Checklist',
      label: 'ImportChecklist',
      value: 'ImportChecklist',
      isRequired: false,
      isChecked: false,
    },
  },

  documentTypeDetails: {
    invoices: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        exceptionType: {
          label: '',
          visibility: false,
          required: false,
          type: 'string',
        },
        subExceptionType: {
          label: '',
          visibility: false,
          required: false,
          type: 'string',
        },
        error_fields: {
          label: '',
          visibility: false,
          required: false,
          type: 'string',
        },

        doc_number_value: {
          label: 'Invoice Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_supplier_name_value: {
          label: 'Supplier Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_date_value: {
          label: 'Invoice Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_importer_name_value: {
          label: 'Importer',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_shipment_origin_value: {
          label: 'Shipment Origin',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_entry_date_value: {
          label: 'Entry Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_shipment_date_value: {
          label: 'Shipment Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_shipment_number_value: {
          label: 'Shipment Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_gst_number_value: {
          label: 'Supplier GSTN',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_po_number_value: {
          label: 'PO Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_vat_number_value: {
          label: 'VAT Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_barcode_data_value: {
          label: 'Barcode data',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_total_amount_value: {
          label: 'Total',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_currency_value: {
          label: 'Currency',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_origin_value: {
          label: 'Origin',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_due_date_value: {
          label: 'Due Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_application_no_value: {
          label: 'Application Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_customer_address_value: {
          label: 'Customer Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_address_value: {
          label: 'Supplier Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_total_tax_amount_value: {
          label: 'Total VAT',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_exshowroom_amount_value: {
          label: 'Balance Due',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_customer_gst_number_value: {
          label: 'Customer GSTN',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_customer_state_value: {
          label: 'Customer State Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_state_value: {
          label: 'Supplier State name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_loan_number_value: {
          label: 'Loan Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_payment_type_value: {
          label: 'Payment Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_name_value: {
          label: 'Customer Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_type_value: {
          label: 'Document Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_pincode_value: {
          label: 'Supplier Pincode',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_customer_pincode_value: {
          label: 'Customer Pincode',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_company_identification_number_value: {
          label: 'CIN Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_chassis_number_value: {
          label: 'Chassis Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_engine_number_value: {
          label: 'Engine Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_model_number_value: {
          label: 'Model Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_vat_number_value: {
          label: 'Customer VAT',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_delivery_number_value: {
          label: 'Delivery Note',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_grn_number_value: {
          label: 'GRN Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_ses_number_value: {
          label: 'SES Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_tax_percentage_value: {
          label: 'VAT Percentage',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_hypothecation_value: {
          label: 'Hypothecation',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_lessor_name_value: {
          label: 'Lessor Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_lessee_name_value: {
          label: 'Lessee Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_contact_ref_value: {
          label: 'Supplier Contact Reference Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_contact_person_name_value: {
          label: 'Lessee Contact Reference Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_employee_ref_value: {
          label: 'Lessor Contact Reference Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_inv_lessor_address_value: {
          label: 'Invoice Lessor Address Value',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_lessee_address_value: {
          label: 'Invoice Lessee Addreess Value',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_cess_amount_value: {
          label: 'CESS',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_tcs_amount_value: {
          label: 'TCS',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_discount_amount_value: {
          label: 'Discount Amount',
          visibility: false,
          required: false,
          type: 'doc_tcs_amount_value',
        },
        doc_vehicle_registration_number_value: {
          label: 'Vehicle Reg Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_hsn_value: {
          label: 'HSN',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_lessor_number_value: {
          label: 'Lessor GSTIN',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_lessee_gst_number_value: {
          label: 'Lessee GSTIN',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_mobile_number_value: {
          label: 'Supplier Contact Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_lessee_contact_person_phone_number_value: {
          label: 'Lessee Contact Person Phone',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_lessee_contact_person_email_value: {
          label: 'Lessee Contact Person Email',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_insurance_amount_value: {
          label: 'Insurances Value',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_registration_charges_value: {
          label: 'Registration Value',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_email_value: {
          label: "Buyer's eMail Address",
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_taxable_amount_value: {
          label: 'Taxable Value',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_supplier_pan_number_value: {
          label: 'Supplier PAN Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_invoice_ref_number_value: {
          label: 'IRN Number',
          visibility: false,
          required: false,
          type: 'doc_taxable_amount_value',
        },
        doc_customer_pan_number_value: {
          label: 'Customer PAN Number',
          visibility: false,
          required: false,
          type: 'doc_taxable_amount_value',
        },
        doc_gst_rate_value: {
          label: 'GST Rate',
          visibility: false,
          required: false,
          type: 'doc_taxable_amount_value',
        },
        doc_gst_amount_value: {
          label: 'GST Amount',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_quote_number_value: {
          label: 'Quote Number',
          visibility: false,
          required: false,
          type: 'doc_taxable_amount_value',
        },
        doc_sales_order_number_value: {
          label: 'Sales Order No',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_payment_terms_value: {
          label: 'Payment Terms',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_shipping_terms_value: {
          label: 'Shipping Terms',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_weight_inkg_value: {
          label: 'Net Wt. In KG',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_shipto_address_value: {
          label: 'Ship To Party Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_mobile_number_value: {
          label: 'Mobile Number',
          visibility: false,
          required: false,
          type: 'doc_weight_inkg_value',
        },
        doc_supplier_email_value: {
          label: 'Suppllier Email Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        ipef: {
          label: 'Image Quality Error',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_mobile_numbers_list_value: {
          label: 'Mobile Number List',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_cgst_percentage_value: {
          label: 'CGST %',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_cgst_amount_value: {
          label: 'CGST Value',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_sgst_percentage_value: {
          label: 'SGST %',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_sgst_amount_value: {
          label: 'SGST Value',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_igst_percentage_value: {
          label: 'IGST %',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_igst_amount_value: {
          label: 'IGST Value',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_shipper_name_value: {
          label: 'Shipper Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_shipper_address_value: {
          label: 'Shipper Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_consignee_name_value: {
          label: 'Ship-to Party Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_consignee_address_value: {
          label: 'Ship-to Party Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_hs_code_value: {
          label: 'HS Code',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_weight_inlb_value: {
          label: 'Weight in lb',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_bill_of_lading_date_value: {
          label: 'Bill of Lading Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_container_number_value: {
          label: 'Container Number',
          visibility: false,
          required: false,
          type: 'doc_weight_inlb_value',
        },
        doc_perfoma_invoice_date_value: {
          label: 'Proforma Invoices Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_sales_cond_value: {
          label: 'Sales condition',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_credit_number_value: {
          label: 'Documentary Credit Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_lc_date_value: {
          label: 'LC date of issue',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_fright_value: {
          label: 'Freight',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_shipped_via_value: {
          label: 'Shipped Via',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_description_of_goods_value: {
          label: 'Description of Goods',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_beneficiary_name_value: {
          label: 'Beneficiary',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_baneficiary_address_value: {
          label: 'Beneficiarys Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_payment_date_value: {
          label: 'Payment Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_digital_sign_date1_value: {
          label: 'Digital Signature Date 1',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_digital_sign_date2_value: {
          label: 'Digital Signature Date 2',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_producer_value: {
          label: 'PDF Producer',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_creator_value: {
          label: 'PDF Creator',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_modified_date_value: {
          label: 'PDF Modified Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_place_of_loading_value: {
          label: 'Port Of Loading',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_place_of_discharge_value: {
          label: 'Port Of Discharge',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_awb_number_value: {
          label: 'Airway Bill Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_hawb_number_value: {
          label: 'House Airway Bill Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_total_pieces_value: {
          label: 'Total Pieces',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_full_box_equivalent_value: {
          label: 'Full Box Equivalent',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_farm_name_value: {
          label: 'Farm Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_consignee_code_value: {
          label: 'Final Customer Code',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_chargeable_weight_value: {
          label: 'Chargeable Weight',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_carrier_value: {
          label: 'Carrier',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_total_tax_value: {
          label: 'Total Tax',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_subtotal_value: {
          label: 'Sub-Total',
          visibility: false,
          required: false,
          type: 'number',
        },
        JOBNV: {
          label: 'Job Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_duty_charges_value: {
          label: 'Duty Charges',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_supplier_phn_number_value: {
          label: 'Supplier Phone Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_supplier_fax_number_value: {
          label: 'Supplier Fax Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_stamp_sign_exist_flag_value: {
          label: 'Stamp Or Sign Existence Flag',
          visibility: false,
          required: false,
          type: 'boolean',
        },
        doc_stamp_exist_flag_value: {
          label: 'Stamp Exist Flag',
          visibility: false,
          required: false,
          type: 'boolean',
        },
        doc_sign_exist_flag_value: {
          label: 'Sign Exist Flag',
          visibility: false,
          required: false,
          type: 'boolean',
        },
        doc_set_reference_number_value: {
          label: 'Set Reference Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_vessel_name_value: {
          label: 'Vessel Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_voyage_number_value: {
          label: 'Voyage Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        COPYTV: {
          label: 'Copy Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_quantity_unit_value: {
          label: 'Unit of quantity',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_quantity_value: {
          label: 'Total quantity',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_net_weight_unit_value: {
          label: 'Unit Total Net Weight',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_unit_weight_value: {
          label: 'Unit Weight',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_gross_weight_value: {
          label: 'Total Gross Weight',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_description_of_goods_concise_value: {
          label: 'Concise Description of Goods',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_precise_supplier_address_value: {
          label: 'Precise Supplier Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_precise_customer_address_value: {
          label: 'Precise Customer Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_std_supplier_name_value: {
          label: 'Standardized Supplier Name',
          visibility: false,
          required: false,
          type: 'string',
        },

        creditSupplier: {
          label: 'Credit Supplier',
          visibility: false,
          required: false,
          type: 'string',
        },
        debitPurchase: {
          label: 'Debit Purchase',
          visibility: false,
          required: false,
          type: 'string',
        },

        lineItemTable: {
          label: 'Line Item Table',
          visibility: true,
          required: false,
          type: 'string',
        },
        dataItemTable: {
          label: 'Data Item Table',
          visibility: true,
          required: false,
          type: 'string',
        },

        LI_description: {
          label: 'LI Description',
          visibility: true,
          required: true,
          type: 'string',
        },
        LI_unit_rate: {
          label: 'LI Unit Rate',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_quantity: {
          label: 'LI Quantity',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_line_item_total: {
          label: 'LI Total',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_pieces: {
          label: 'LI Pieces',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_quantity_ordered: {
          label: 'LI Quantity Ordered',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_quantity_shipped: {
          label: 'LI Quantity Shipped',
          visibility: false,
          required: false,
          type: 'string',
        },
        LI_box_type: {
          label: 'LI Box Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        LI_product_code: {
          label: 'LI Product Code',
          visibility: false,
          required: false,
          type: 'string',
        },
        LI_total_units: {
          label: 'LI Total Units',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_unit_price: {
          label: 'LI Unit Price',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_units_per_box: {
          label: 'LI Units Per Box',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_total_bunches: {
          label: 'LI Total Bunches',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_unit_type: {
          label: 'LI Unit Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        LI_stems_per_bunch: {
          label: 'LI Stems per Bunch',
          visibility: false,
          required: false,
          type: 'string',
        },
        LI_net_weight: {
          label: 'LI Net Weight',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_gross_weight: {
          label: 'LI Gross Weight',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_item_code: {
          label: 'LI Item Code',
          visibility: false,
          required: false,
          type: 'string',
        },
        LI_hsn: {
          label: 'LI HSN',
          visibility: false,
          required: false,
          type: 'string',
        },
        LI_configuration: {
          label: 'LI Configuration',
          visibility: false,
          required: false,
          type: 'string',
        },
        LI_cgst_amount: {
          label: 'LI CGST Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_cgst_rate: {
          label: 'LI CGST Rate',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_sgst_amount: {
          label: 'LI SGST Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_sgst_rate: {
          label: 'LI SGST Rate',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_igst_amount: {
          label: 'LI IGST Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_igst_rate: {
          label: 'LI IGST Rate',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_gst_amount: {
          label: 'LI GST Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_gst_rate: {
          label: 'LI GST Rate',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_service_tax_rate: {
          label: 'LI Service Tax Rate',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_service_tax_amount: {
          label: 'LI Service Tax Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_discount_rate: {
          label: 'LI Discount Rate',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_discount_amount: {
          label: 'LI Discount Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_cess_rate: {
          label: 'LI CESS Rate',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_cess_amount: {
          label: 'LI CESS Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_net_amount: {
          label: 'LI Net Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        LI_unit: {
          label: 'LI Unit',
          visibility: false,
          required: false,
          type: 'string',
        },
        LI_serial_number: {
          label: 'LI Serial Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        LI_batch_number: {
          label: 'LI Batch Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        LI_model: {
          label: 'LI Model',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_discount_due_date: {
          label: 'Discount Due Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_tax_type: {
          label: 'Tax Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_tax_category: {
          label: 'Tax Category',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_discount_percentage: {
          label: 'Discount Percentage',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_withholding_tax: {
          label: 'Withholding Tax',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_tds_rate: {
          label: 'TDS Rate',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_tds_amount: {
          label: 'TDS Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_excise_charges: {
          label: 'Excise Charges',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_extra_charges: {
          label: 'Extra Charges',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_contract_id: {
          label: 'Contract Id',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_credit_note: {
          label: 'Credit Note',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_bank_account_number: {
          label: 'Bank Account Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_grower_name_value: {
          label: 'Grower Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_website_value: {
          label: 'Supplier Website',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_payment_reference_value: {
          label: 'Payment Reference',
          visibility: false,
          required: false, 
          type: 'string'
        },
        doc_supplier_tax_id_value:{
          label: 'Supplier Tax ID',
          visibility: false,
          required: false, 
          type: 'string'
        }
      },

      quickBookPref: {
        status: 'on',
      },
      taxSystem: 'indian',
      payments: {
        status: false,
        customerBankAccountNumber: '',
        paymentSchedule: {},
      },
      show: true,
    },
    receipts: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },

        exceptionType: {
          label: '',
          visibility: false,
          required: false,
          type: 'string',
        },
        subExceptionType: {
          label: '',
          visibility: false,
          required: false,
          type: 'string',
        },
        error_fields: {
          label: 'Error Fields',
          visibility: false,
          required: false,
          type: 'string',
        },

        doc_number_value: {
          label: 'Receipt Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_supplier_name_value: {
          label: 'Supplier Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_date_value: {
          label: 'Receipt Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_shipment_number_value: {
          label: 'Shipment Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_gst_number_value: {
          label: 'Supplier GSTN',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_po_number_value: {
          label: 'PO Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_vat_number_value: {
          label: 'VAT Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_total_amount_value: {
          label: 'Total',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_currency_value: {
          label: 'Currency',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_origin_value: {
          label: 'Origin',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_due_date_value: {
          label: 'Due Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_application_no_value: {
          label: 'Application Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_customer_address_value: {
          label: 'Customer Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_address_value: {
          label: 'Supplier Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_total_tax_amount_value: {
          label: 'Total VAT',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_exshowroom_amount_value: {
          label: 'Balance Due',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_customer_gst_number_value: {
          label: 'Customer GSTN',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_customer_state_value: {
          label: 'Customer State Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_state_value: {
          label: 'Supplier State name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_city_value: {
          label: 'Supplier City Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_city_value: {
          label: 'Customer City Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_loan_number_value: {
          label: 'Loan Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_payment_type_value: {
          label: 'Payment Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_name_value: {
          label: 'Customer Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_type_value: {
          label: 'Document Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_pincode_value: {
          label: 'Supplier Pincode',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_customer_pincode_value: {
          label: 'Customer Pincode',
          visibility: false,
          required: false,
          type: 'number',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_customer_mobile_number_value: {
          label: 'Mobile Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_supplier_email_value: {
          label: 'Supplier Email Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        ipef: {
          label: '',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_mobile_numbers_list_value: {
          label: 'Mobile Number List',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_cgst_percentage_value: {
          label: 'CGST %',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_cgst_amount_value: {
          label: 'CGST Value',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_sgst_percentage_value: {
          label: 'SGST %',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_sgst_amount_value: {
          label: 'SGST Value',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_igst_percentage_value: {
          label: 'IGST %',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_igst_amount_value: {
          label: 'IGST Value',
          visibility: false,
          required: false,
          type: 'number',
        },

        creditSupplier: {
          label: 'Credit Supplier',
          visibility: false,
          required: false,
          type: 'string',
        },
        debitPurchase: {
          label: 'Debit Purchase',
          visibility: false,
          required: false,
          type: 'string',
        },

        lineItemTable: {
          label: 'Line Item Table',
          visibility: true,
          required: false,
          type: 'string',
        },
        dataItemTable: {
          label: 'Data Item Table',
          visibility: true,
          required: false,
          type: 'string',
        },
      },

      show: true,
    },
    purchaseorders: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        doc_supplier_name_value: {
          label: 'Supplier Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_date_value: {
          label: 'Puchase Order Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_shipment_number_value: {
          label: 'Shipment Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_gst_number_value: {
          label: 'Supplier GSTN',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_po_number_value: {
          label: 'PO Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_vat_number_value: {
          label: 'VAT Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_total_amount_value: {
          label: 'Total',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_currency_value: {
          label: 'Currency',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_origin_value: {
          label: 'Origin',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_due_date_value: {
          label: 'Due Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_application_no_value: {
          label: 'Application Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_customer_address_value: {
          label: 'Customer Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_address_value: {
          label: 'Supplier Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_total_tax_amount_value: {
          label: 'Total VAT',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_exshowroom_amount_value: {
          label: 'Balance Due',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_customer_gst_number_value: {
          label: 'Customer GSTN',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_customer_state_value: {
          label: 'Customer State Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_state_value: {
          label: 'Supplier State name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_city_value: {
          label: 'Supplier City Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_city_value: {
          label: 'Customer City name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_loan_number_value: {
          label: 'Loan Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_payment_type_value: {
          label: 'Payment Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_name_value: {
          label: 'Customer Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_type_value: {
          label: 'Document Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_pincode_value: {
          label: 'Supplier Pincode',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_customer_pincode_value: {
          label: 'Customer Pincode',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_customer_mobile_number_value: {
          label: 'Mobile Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_supplier_email_value: {
          label: 'Supplier Email Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_fright_value: {
          label: 'Freight',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_shipto_address_value: {
          label: 'Ship To Party Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_email_value: {
          label: "Buyer's  eMail Address",
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_mobile_numbers_list_value: {
          label: 'Customer Mobile Number List',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_cgst_percentage_value: {
          label: 'CGST %',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_cgst_amount_value: {
          label: 'CGST Value',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_sgst_percentage_value: {
          label: 'SGST %',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_sgst_amount_value: {
          label: 'SGST Value',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_igst_percentage_value: {
          label: 'IGST %',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_igst_amount_value: {
          label: 'IGST Value',
          visibility: false,
          required: false,
          type: 'number',
        },

        creditSupplier: {
          label: 'Credit Supplier',
          visibility: false,
          required: false,
          type: 'string',
        },
        debitPurchase: {
          label: 'Debit Purchase',
          visibility: false,
          required: false,
          type: 'string',
        },

        lineItemTable: {
          label: 'Line Item Table',
          visibility: false,
          required: false,
          type: 'string',
        },
        dataItemTable: {
          label: 'Data Item Table',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: true,
    },
    rfq: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        doc_quote_number_value: {
          label: 'Quote Id',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_name_value: {
          label: 'Customer Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_product_name_value: {
          label: 'Product Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_start_date_value: {
          label: 'Start Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_end_date_value: {
          label: 'End Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_terms_value: {
          label: 'Payment Date',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_payment_method_value: {
          label: 'Payment Method',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_consumption_threshold_value: {
          label: 'Consumption Threshold',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_volume_tolerance_value: {
          label: 'Volume Tolerance',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_material_amount_value: {
          label: 'Material Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_contract_reference_number_value: {
          label: 'Contract Reference Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_total_estimation_consumption_value: {
          label: 'Total Estimation Consumption',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_regulatory_compliance_value: {
          label: 'Regulatory Compliance',
          visibility: false,
          required: false,
          type: 'string',
        },

        lineItemTable: {
          label: 'Line Item Table',
          visibility: false,
          required: false,
          type: 'string',
        },
        dataItemTable: {
          label: 'Data Item Table',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    nach: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        nach_image_quality_value: {
          label: 'Nach Image Quality',
          visibility: false,
          required: false,
          type: 'string',
        },
        nach_account_number: {
          label: 'Nach A/C No.',
          visibility: false,
          required: false,
          type: 'string',
        },
        ntype: {
          label: 'Nach Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        nach_reference_number_1_flag: {
          label: 'Nach Ref Number1',
          visibility: false,
          required: false,
          type: 'boolean',
        },
        nach_sign_exist_flag: {
          label: 'Nach Signature Exist',
          visibility: false,
          required: false,
          type: 'boolean',
        },
        nach_bank_name_exist_flag: {
          label: 'Nach Bank Name Exist',
          visibility: false,
          required: false,
          type: 'boolean',
        },
        nach_bank_name: {
          label: 'Bank Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        ndt: {
          label: 'Debit Type',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    insurances: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        doc_customer_name_value: {
          label: 'Customer Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_address_value: {
          label: 'Customer Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_date_value: {
          label: 'Policy Start Date',
          visibility: false,
          required: false,
          type: 'date',
        },

        doc_model_number_value: {
          label: 'Model',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_engine_number_value: {
          label: 'Engine Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_chassis_number_value: {
          label: 'Chassis Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_hypothecation_value: {
          label: 'Hypothecation',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_IDEC_value: {
          label: 'Insured Declared Value',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_ins_policy_no_value: {
          label: 'Policy Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_reg_no_value: {
          label: 'Vehicle Regestration Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_fuel_type_value: {
          label: 'Fuel Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_cubic_capacity_value: {
          label: 'Cubic Capacity',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_policy_end_date_value: {
          label: 'Policy End Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_year_of_manufacture_value: {
          label: 'Year of Manufacture',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_name_value: {
          label: 'Insured Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_pincode_value: {
          label: 'Customer Pincode Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_customer_state_value: {
          label: 'Customer State Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_mobile_number_value: {
          label: 'Customer Mobile Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_insurance_net_premium_value: {
          label: 'Total Premium',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_insurance_total_premium_value: {
          label: 'Net Premium',
          visibility: false,
          required: false,
          type: 'number',
        },

        lineItemTable: {
          label: '',
          visibility: false,
          required: false,
          type: 'string',
        },
        dataItemTable: {
          label: '',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    AddressDocument: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        CNV_0: {
          label: 'To:Customer Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        CAV_0: {
          label: 'To:Customer Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        CPNV_0: {
          label: 'To:Customer Pincode Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        CMNV_0: {
          label: 'To:Customer Mobile Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        PIN_GIO_0: {
          label: 'To:GEO Location Details',
          visibility: false,
          required: false,
          type: 'string',
        },
        CNV_1: {
          label: 'From:Customer Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        CAV_1: {
          label: 'From:Customer Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        CPNV_1: {
          label: 'From:Customer Pincode Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        CMNV_1: {
          label: 'From:Customer Mobile Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        PIN_GIO_1: {
          label: 'From:GEO Location Details',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    idcards: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        ADNV: {
          label: 'Aadhaar Card Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        ADDV: {
          label: 'Aadhaar Card Date of Birth',
          visibility: false,
          required: false,
          type: 'date',
        },
        ADID: {
          label: 'Aadhaar Card Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        ADAV: {
          label: 'Aadhaar Card Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        ADGV: {
          label: 'Aadhaar Card Gender',
          visibility: false,
          required: false,
          type: 'string',
        },
        PCID: {
          label: 'PAN Card Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        PCDV: {
          label: 'PAN Card Date of Birth',
          visibility: false,
          required: false,
          type: 'date',
        },
        PCNV: {
          label: 'PAN card Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        MCID: {
          label: 'Malaysian Card Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        MCNV: {
          label: 'Malaysian Card Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        MCAV: {
          label: 'Malaysian Card Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        MCPV: {
          label: 'Malaysian Card Pincode',
          visibility: false,
          required: false,
          type: 'string',
        },

        IPGNV: {
          label: 'Given Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPSV: {
          label: 'Sex',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPDOBV: {
          label: 'Date of Birth',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPTV: {
          label: 'Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPCCV: {
          label: 'Country Code',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPSNV: {
          label: 'Surname',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPPBV: {
          label: 'Place of Birth',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPPIV: {
          label: 'Place of Issue',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPDIV: {
          label: 'Date of issue',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPDEV: {
          label: 'Date of Expiry',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPPNV: {
          label: 'Passport Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPFNV: {
          label: 'Father Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPSPNV: {
          label: 'Spouse Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPMNV: {
          label: 'Mother Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPFLV: {
          label: 'File Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPAV: {
          label: 'Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPOPV: {
          label: 'Old Passport Information',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPSIGNV: {
          label: 'Passport Signature',
          visibility: false,
          required: false,
          type: 'string',
        },
        IPPV: {
          label: 'Passport Photo',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    marksheets: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        MFNV: {
          label: 'Foil Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        MZNV: {
          label: 'Zone Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        MPCV: {
          label: 'Paper Code',
          visibility: false,
          required: false,
          type: 'string',
        },
        MECCV: {
          label: 'Exam center code',
          visibility: false,
          required: false,
          type: 'string',
        },
        MPNV: {
          label: 'Paper Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        MTMV: {
          label: 'Total Marks',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_subject_name_value: {
          label: 'Subject Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        MENCV: {
          label: 'Examination Name',
          visibility: false,
          required: false,
          type: 'string',
        },

        lineItemTable: {
          label: 'Line Item Table',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    BolDocument: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        doc_shipment_number_value: {
          label: 'Shipment Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_po_number_value: {
          label: 'PO Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_weight_inkg_value: {
          label: 'Weight in kg',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_net_weight_unit_value: {
          label: 'Unit Total Net Weight',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_weight_inlb_value: {
          label: 'Weight in lb',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_hs_code_value: {
          label: 'HS Code',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_shipper_name_value: {
          label: 'Shipper Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_shipper_address_value: {
          label: 'Shipper Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_consignee_name_value: {
          label: 'Ship-To Party Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_consignee_address_value: {
          label: 'Ship-To Party Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_place_of_reciept_value: {
          label: 'Place Of Receipt',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_place_of_loading_value: {
          label: 'Port Of Loading',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_place_of_discharge_value: {
          label: 'Port Of Discharge',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_place_of_delivery_value: {
          label: 'Place Of Delivery',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_number_of_packages_value: {
          label: 'N° Of Packages',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_gross_weight_value: {
          label: 'Total Gross Weight',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_unit_weight_value: {
          label: 'Unit Weight',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_measurement_value: {
          label: 'Measurement',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_measurement_unit_value: {
          label: 'Measurement Unit',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_container_number_value: {
          label: 'Container N°',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_seal_number_value: {
          label: 'Seal N°',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_container_type_value: {
          label: 'Container Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_total_number_of_container_value: {
          label: 'Total Number Of Containers',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_perfoma_invoice_date_value: {
          label: "Proforma Invoice's Date",
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_sales_cond_value: {
          label: 'Sales condition',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_credit_number_value: {
          label: 'Documentary Credit Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_lc_date_value: {
          label: 'LC Date Of Issue',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_fright_value: {
          label: 'Freight',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_fright_charging_value: {
          label: 'Freight Charges Payable At',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_shipped_board_date_value: {
          label: 'Shipped on Board Date',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_num_of_original_value: {
          label: 'N° of originals',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_signatory_value: {
          label: 'Signatory',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_carrier_value: {
          label: 'Carrier',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_description_of_goods_value: {
          label: 'Description of Goods',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_vessel_name_value: {
          label: 'Vessel Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_origin_value: {
          label: 'Origin',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_set_reference_number_value: {
          label: 'Set Reference Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_voyage_number_value: {
          label: 'Voyage Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_bol_date_of_issue_value: {
          label: 'Date of Issue',
          visibility: false,
          required: false,
          type: 'date',
        },
        COPYTV: {
          label: 'Copy Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_type_value: {
          label: 'Document Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_quantity_unit_value: {
          label: 'Unit of quantity',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_stamp_exist_flag_value: {
          label: 'Stamp Exist Flag',
          visibility: false,
          required: false,
          type: 'boolean',
        },
        doc_sign_exist_flag_value: {
          label: 'Sign Exist Flag',
          visibility: false,
          required: false,
          type: 'boolean',
        },
        doc_bill_of_lading_number_value: {
          label: 'BOL Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_description_of_goods_concise_value: {
          label: 'Concise Description of Goods',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_precise_shipper_address_value: {
          label: 'Precise Shipper Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_precise_consignee_address_value: {
          label: 'Precise Consignee Address',
          visibility: false,
          required: false,
          type: 'string',
        },

        lineItemTable: {
          label: '',
          visibility: false,
          required: false,
          type: 'string',
        },
        dataItemTable: {
          label: '',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    PackingList: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        doc_number_value: {
          label: 'Invoice Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_supplier_name_value: {
          label: 'Supplier Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_date_value: {
          label: 'Invoice Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_po_number_value: {
          label: 'PO Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_supplier_address_value: {
          label: 'Supplier Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_name_value: {
          label: 'Customer Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_address_value: {
          label: 'Customer Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_shipper_name_value: {
          label: 'Shipper',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_shipper_address_value: {
          label: 'Shipper Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_consignee_name_value: {
          label: 'Receiver',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_consignee_address_value: {
          label: 'Receiver Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_packing_list_number: {
          label: 'Packing List Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_due_date_value: {
          label: 'Due Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_bill_of_lading_date_value: {
          label: 'Bill of Lading Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_perfoma_invoice_date_value: {
          label: 'Proforma Invoice Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_sales_cond_value: {
          label: 'Sales Condition',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_credit_number_value: {
          label: 'Documentary Credit Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_lc_date_value: {
          label: 'LC Date of Issue',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_currency_value: {
          label: 'Currency ',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_type_value: {
          label: 'Document Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_billto_address_value: {
          label: 'To',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_container_number_value: {
          label: 'Container Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_fright_value: {
          label: 'Freight',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_origin_value: {
          label: 'ORIGIN OF GOODS',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_measurement_value: {
          label: 'Measurement',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_measurement_unit_value: {
          label: 'Measurement Unit',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_gross_weight_value: {
          label: 'Total Gross Weight',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_unit_weight_value: {
          label: 'Unit Gross Weight',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_net_weight_unit_value: {
          label: 'Unit Total Net Weight',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_weight_inkg_value: {
          label: 'Total Net Weight',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_quantity_value: {
          label: 'Total quantity',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_quantity_unit_value: {
          label: 'Unit of quantity',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_shipped_via_value: {
          label: 'Shipped via',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_beneficiary_name_value: {
          label: 'Beneficiary',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_baneficiary_address_value: {
          label: 'Beneficiary Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_description_of_goods_value: {
          label: 'Description of Goods',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_place_of_loading_value: {
          label: 'Port Of Loading',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_place_of_discharge_value: {
          label: 'Port Of Discharge',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_vessel_name_value: {
          label: 'Vessel Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_set_reference_number_value: {
          label: 'Set Reference Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_voyage_number_value: {
          label: 'Voyage Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        COPYTV: {
          label: 'Copy Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_shipped_board_date_value: {
          label: 'Shipped on Board Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_description_of_goods_concise_value: {
          label: 'Concise Description of Goods',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_precise_shipper_address_value: {
          label: 'Precise Shipper Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_precise_consignee_address_value: {
          label: 'Precise Consignee Address',
          visibility: false,
          required: false,
          type: 'string',
        },

        lineItemTable: {
          label: '',
          visibility: false,
          required: false,
          type: 'string',
        },
        dataItemTable: {
          label: '',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    BillofExchange: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        doc_type_value: {
          label: 'Document Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_drown_under_value: {
          label: 'Drawn Under',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_credit_number_value: {
          label: 'Documentary Credit Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_number_value: {
          label: 'Invoice Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_due_date_value: {
          label: 'Due Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_bill_of_lading_date_value: {
          label: 'Bill of Lading Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_total_amount_value: {
          label: 'Total Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_currency_value: {
          label: 'Currency',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_pay_to_order_of_value: {
          label: 'Pay to Order of',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_origin_value: {
          label: 'Origin',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_stamp_sign_exist_flag_value: {
          label: 'Stamp Or Sign Existence Flag',
          visibility: false,
          required: false,
          type: 'string',
        },

        doc_due_in_days_value: {
          label: 'Due in Days',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_stamp_exist_flag_value: {
          label: 'Stamp Exist Flag',
          visibility: false,
          required: false,
          type: 'boolean',
        },
        doc_sign_exist_flag_value: {
          label: 'Sign Exist Flag',
          visibility: false,
          required: false,
          type: 'boolean',
        },
        doc_set_reference_number_value: {
          label: 'Set Reference Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_beneficiary_name_value: {
          label: 'Beneficiary Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        COPYTV: {
          label: 'Copy Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_boe_number_value: {
          label: 'Bill of Exchange Number',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    BADocument: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        doc_type_value: {
          label: 'Document Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_beneficiary_name_value: {
          label: 'Beneficiary',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_baneficiary_address_value: {
          label: 'Beneficiary Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_certificate_subject_value: {
          label: 'Certification Subject',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_credit_number_value: {
          label: 'Documentary Credit Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_lc_date_value: {
          label: 'LC Date of Issue',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_amendment_date_value: {
          label: 'Date of Amendment',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_signatory_value: {
          label: 'Signatory',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_date_of_signatory_value: {
          label: 'Date of Signatory',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_set_reference_number_value: {
          label: 'Set Reference Number',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    TIFDocument: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        party_name: {
          label: 'Party Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        head_of_tech_details: {
          label: 'Head of Tech Details',
          visibility: false,
          required: false,
          type: 'string',
        },
        escalation_matrix_level_1: {
          label: 'Escalation Matrix Level 1',
          visibility: false,
          required: false,
          type: 'string',
        },
        escalation_matrix_level_2: {
          label: 'Escalation Matrix Level 2',
          visibility: false,
          required: false,
          type: 'string',
        },
        escalation_matrix_level_3: {
          label: 'Escalation Matrix Level 3',
          visibility: false,
          required: false,
          type: 'string',
        },
        website: {
          label: 'Website',
          visibility: false,
          required: false,
          type: 'string',
        },
        payment_url: {
          label: 'Payment URL',
          visibility: false,
          required: false,
          type: 'string',
        },
        push_response_url: {
          label: 'Push Response URL',
          visibility: false,
          required: false,
          type: 'string',
        },
        logo_url: {
          label: 'Logo URL',
          visibility: false,
          required: false,
          type: 'string',
        },
        type_of_settlement_facility: {
          label: 'Type of Settlement Facility',
          visibility: false,
          required: false,
          type: 'string',
        },
        timeout_interval: {
          label: 'Timeout Interval',
          visibility: false,
          required: false,
          type: 'string',
        },
        production_app_sever_ip_address: {
          label: 'Production App Sever IP Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        test_app_server_ip_address: {
          label: 'Test App Server IP Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        static_ip_for_test_integration: {
          label: 'Static IP For Test Integration',
          visibility: false,
          required: false,
          type: 'string',
        },
        host_service_provider_details: {
          label: 'Host Service Provider Details',
          visibility: false,
          required: false,
          type: 'string',
        },
        ssl_certificate_details: {
          label: 'SSL Certificate Details',
          visibility: false,
          required: false,
          type: 'string',
        },
        encryption_type: {
          label: 'Encryption Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        checksum: {
          label: 'Checksum',
          visibility: false,
          required: false,
          type: 'string',
        },
        tech_tools_and_shopping_card_details: {
          label: 'Tech Tools and Shopping Card Details',
          visibility: false,
          required: false,
          type: 'string',
        },
        operating_system: {
          label: 'Operating System',
          visibility: false,
          required: false,
          type: 'string',
        },
        app_server_details: {
          label: 'App Server Details',
          visibility: false,
          required: false,
          type: 'string',
        },
        web_server_details: {
          label: 'Web Server Details',
          visibility: false,
          required: false,
          type: 'string',
        },
        internal_security_plicy_exists: {
          label: 'Internal Security Policy Exists',
          visibility: false,
          required: false,
          type: 'string',
        },
        database_and_app_servers_firewall_exists: {
          label: 'Database and App Servers Firewall Exists',
          visibility: false,
          required: false,
          type: 'string',
        },
        security_patches_reviewd_and_updated: {
          label: 'Security Patches Reviewd and Updated',
          visibility: false,
          required: false,
          type: 'string',
        },
        transaction_logs_maintainance_status: {
          label: 'Transaction Logs Maintainance Status',
          visibility: false,
          required: false,
          type: 'string',
        },
        it_service_provider_details: {
          label: 'It Service Provider Details',
          visibility: false,
          required: false,
          type: 'string',
        },
        storing_user_card_details: {
          label: 'Storing User Card Details',
          visibility: false,
          required: false,
          type: 'string',
        },
        pci_dss_certified: {
          label: 'PCI DSS Certified',
          visibility: false,
          required: false,
          type: 'string',
        },
        pci_dss_certification_details: {
          label: 'PCI DSS Certification Details',
          visibility: false,
          required: false,
          type: 'string',
        },
        storing_sensitive_auth_data: {
          label: 'Storing Sensitive Auth Data',
          visibility: false,
          required: false,
          type: 'string',
        },
        storing_user_internet_banking_detals: {
          label: 'Storing User Internet Banking Details',
          visibility: false,
          required: false,
          type: 'string',
        },
        vulnerability_assessment_status: {
          label: 'Vulnerability Assessment Status',
          visibility: false,
          required: false,
          type: 'string',
        },
        penetration_testing_and_asv_scan_status: {
          label: 'Penetration Testing and ASV Scan Status',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    VSCCDocument: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        site_url_and_host_premise: {
          label: 'Site URL and Host Premise',
          visibility: false,
          required: false,
          type: 'string',
        },
        ssl_certificate_status: {
          label: 'SSL Certificate Status',
          visibility: false,
          required: false,
          type: 'string',
        },
        previous_app_security_date: {
          label: 'Previous App Security Date',
          visibility: false,
          required: false,
          type: 'string',
        },
        closed_findings_evidence: {
          label: 'Closed Findings Evidence',
          visibility: false,
          required: false,
          type: 'string',
        },
        vulnerability_and_external_penetration_test: {
          label: 'Vulnerability and External Penetration Test',
          visibility: false,
          required: false,
          type: 'string',
        },
        vulnerability_and_external_penetration_test_closing_evidence: {
          label: 'Vulnerability and External Penetration Test Closing Evidence',
          visibility: false,
          required: false,
          type: 'string',
        },
        database_and_app_servers_firewall_status: {
          label: 'Database and App Servers Firewall Status',
          visibility: false,
          required: false,
          type: 'string',
        },
        not_running_internet_services_confirmation: {
          label: 'Not Running Internet Services Confirmation',
          visibility: false,
          required: false,
          type: 'string',
        },
        no_storage_of_user_netbanking_credentials_confirmation: {
          label: 'No Storage of User Netbanking Credentials Confirmation',
          visibility: false,
          required: false,
          type: 'string',
        },
        logs_maintainance_status: {
          label: 'Logs Maintainance Status',
          visibility: false,
          required: false,
          type: 'string',
        },
        card_details_capture: {
          label: 'Card Details Capture',
          visibility: false,
          required: false,
          type: 'string',
        },
        merchant_cde_pci_dss_certified: {
          label: 'Merchant CDE PCI DSS Certified',
          visibility: false,
          required: false,
          type: 'string',
        },
        no_storage_of_sensitive_auth_data: {
          label: 'No Storage of Sensitive Auth Data',
          visibility: false,
          required: false,
          type: 'string',
        },
        not_sharing_personal_identifiable_info: {
          label: 'Not Sharing Personal Identifiable Info',
          visibility: false,
          required: false,
          type: 'string',
        },
        security_consultant_name: {
          label: 'Security Consultant Name',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    OnboardingForm: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        party_name: {
          label: 'Party Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        website: {
          label: 'Website',
          visibility: false,
          required: false,
          type: 'string',
        },
        constitution: {
          label: 'Constitution',
          visibility: false,
          required: false,
          type: 'string',
        },
        tan_number: {
          label: 'Tan Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        office_address: {
          label: 'Office Address',
          visibility: false,
          required: false,
          type: 'string',
        },
        state: {
          label: 'State',
          visibility: false,
          required: false,
          type: 'string',
        },
        city: {
          label: 'City',
          visibility: false,
          required: false,
          type: 'string',
        },
        pin_code: {
          label: 'Pin Code',
          visibility: false,
          required: false,
          type: 'number',
        },
        line_of_activity: {
          label: 'Line of Activity',
          visibility: false,
          required: false,
          type: 'string',
        },
        contact_name_and_designation: {
          label: 'Contact Name and Designation',
          visibility: false,
          required: false,
          type: 'string',
        },
        contact_email_id: {
          label: 'Contact Email Id',
          visibility: false,
          required: false,
          type: 'string',
        },
        alternate_contact_email_id: {
          label: 'Alternate Contact Email Id',
          visibility: false,
          required: false,
          type: 'string',
        },
        contact_primary_mobile_no: {
          label: 'Contact Primary Mobile No',
          visibility: false,
          required: false,
          type: 'number',
        },
        contact_landline_no: {
          label: 'Contact Landline No',
          visibility: false,
          required: false,
          type: 'number',
        },
        name_of_bank: {
          label: 'Name of Bank',
          visibility: false,
          required: false,
          type: 'string',
        },
        name_of_branch: {
          label: 'Name of Branch',
          visibility: false,
          required: false,
          type: 'string',
        },
        bank_account_number: {
          label: 'Bank Account Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        bank_account_name: {
          label: 'Bank Account Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        bank_ifsc: {
          label: 'Bank IFSC',
          visibility: false,
          required: false,
          type: 'string',
        },
        bank_account_type: {
          label: 'Bank Account Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        expected_no_of_transactions_pm: {
          label: 'Expected No of Transactions PM',
          visibility: false,
          required: false,
          type: 'string',
        },
        min_transaction_amount: {
          label: 'Min Transaction Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        max_transaction_amount: {
          label: 'Max Transaction Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        avg_transaction_amount: {
          label: 'Average Transaction Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        net_banking: {
          label: 'Net Banking',
          visibility: false,
          required: false,
          type: 'string',
        },
        domestic_debit_cards: {
          label: 'Domestic Debit Cards',
          visibility: false,
          required: false,
          type: 'string',
        },
        domestic_credit_cards: {
          label: 'Domestic Credit Cards',
          visibility: false,
          required: false,
          type: 'string',
        },
        domestic_prepaid_cards: {
          label: 'Domestic Prepaid Cards',
          visibility: false,
          required: false,
          type: 'string',
        },
        upi: {
          label: 'UPI',
          visibility: false,
          required: false,
          type: 'string',
        },
        offline_channels_neft: {
          label: 'Offline Channels Neft',
          visibility: false,
          required: false,
          type: 'string',
        },
        sbi_branch_payment: {
          label: 'SBI Branch Payment',
          visibility: false,
          required: false,
          type: 'string',
        },
        csc_wallet: {
          label: 'CSC Wallet',
          visibility: false,
          required: false,
          type: 'string',
        },
        amex_credit_card: {
          label: 'Amex Credit Card',
          visibility: false,
          required: false,
          type: 'string',
        },
        international_debit_cards: {
          label: 'International Debit Cards',
          visibility: false,
          required: false,
          type: 'string',
        },
        international_credit_cards: {
          label: 'International Credit Cards',
          visibility: false,
          required: false,
          type: 'string',
        },
        paypal: {
          label: 'Paypal',
          visibility: false,
          required: false,
          type: 'string',
        },
        website_technology: {
          label: 'Website Technology',
          visibility: false,
          required: false,
          type: 'string',
        },
        settlement_type_facility: {
          label: 'Settlement Type Facility',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    AirwayBill: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        doc_date_value: {
          label: 'Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_awb_number_value: {
          label: 'Airway Bill Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_total_pieces_value: {
          label: 'Total Pieces',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_set_reference_number_value: {
          label: 'Set Reference Number',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    dealerquotation: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        doc_supplier_name_value: {
          label: 'Supplier Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_customer_name_value: {
          label: 'Customer Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_chassis_number_value: {
          label: 'Chassis Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_hypothecation_value: {
          label: 'Hypothecation',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_engine_number_value: {
          label: 'Engine Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_model_number_value: {
          label: 'Model Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_application_no_value: {
          label: 'Application Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_dq_registration_no_value: {
          label: 'Registration Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_dq_registration_date_value: {
          label: 'Registration Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_dq_manufacturer_name_value: {
          label: 'Manufacturer Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_dq_odometer_reading_value: {
          label: 'Odometer Reading',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_dq_rc_verified_status_value: {
          label: 'RC Verified Status',
          visibility: false,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    LoanNotices: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        doc_effeective_date_value: {
          label: 'Effective Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_start_date_value: {
          label: 'Start Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_end_date_value: {
          label: 'End Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_due_date_value: {
          label: 'Maturity Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_repricing_date_value: {
          label: 'Repricing Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_repricing_frequency_value: {
          label: 'Repricing Frequency',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_branch_name_value: {
          label: 'Branch Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_borrower_name_value: {
          label: 'Borrower Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_deal_ref_name_value: {
          label: 'Deal Reference Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_requested_amount_value: {
          label: 'Requested Amount',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_facility_name_value: {
          label: 'Facility Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_currency_value: {
          label: 'Currency',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_loan_notice_type_value: {
          label: 'Notice Type',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_loan_notice_subtype_value: {
          label: 'Notice Subtype',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_loan_notice_basis_value: {
          label: 'Basis',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_loan_notice_margin_value: {
          label: 'Margin',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_loan_notice_interest_rate_value: {
          label: 'Interest Rate',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_drawdown_start_date_value: {
          label: 'Start Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_drawdown_end_date_value: {
          label: 'End Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_drawdown_requested_amt_value: {
          label: 'Requested Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_drawdown_global_amt_value: {
          label: 'Global Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_drawdown_basis_duration: {
          label: 'Basis Duration',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_drawdown_aiper_value: {
          label: 'All In Percentage',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_drawdown_description_value: {
          label: 'Description',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_repricing_start_date_value: {
          label: 'Start Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_repricing_end_date_value: {
          label: 'End Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_repricing_requested_amt_value: {
          label: 'Requested Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_repricing_global_amt_value: {
          label: 'Global Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_repricing_aiper_value: {
          label: 'All In Percentage',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_repricing_description_value: {
          label: 'Description',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_intrest_requested_amt_value: {
          label: 'Requested Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_intrest_global_amt_value: {
          label: 'Global Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_intrest_description_value: {
          label: 'Description',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_outstanding_start_date_value: {
          label: 'Start Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_outstanding_end_date_value: {
          label: 'End Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_outstanding_requested_amt_value: {
          label: 'Requested Amount',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_outstanding_global_amt_value: {
          label: 'Global Amount',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_outstanding_description_value: {
          label: 'Description',
          visibility: false,
          required: false,
          type: 'number',
        },
      },

      show: false,
    },
    Property_Appraisal: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        agency_name: {
          label: 'Agency Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        date_of_visit: {
          label: 'Date of Visit',
          visibility: false,
          required: false,
          type: 'date',
        },
        type_of_property: {
          label: 'Type of Property',
          visibility: false,
          required: false,
          type: 'string',
        },
        current_usage: {
          label: 'Current Usage',
          visibility: false,
          required: false,
          type: 'string',
        },
        approved_usage_of_property: {
          label: 'Approved Usage of Property',
          visibility: false,
          required: false,
          type: 'string',
        },
        address_at_site: {
          label: 'Address At Site',
          visibility: false,
          required: false,
          type: 'string',
        },
        address_as_per_document: {
          label: 'Address As Per Document',
          visibility: false,
          required: false,
          type: 'string',
        },
        occupancy_status: {
          label: 'Occupancy Status',
          visibility: false,
          required: false,
          type: 'string',
        },
        exterior_construction_quality: {
          label: 'Exterior Construction Quality',
          visibility: false,
          required: false,
          type: 'string',
        },
        interior_construction_quality: {
          label: 'Interior Construction Quality',
          visibility: false,
          required: false,
          type: 'string',
        },
        age_of_the_property: {
          label: 'Age of The Property',
          visibility: false,
          required: false,
          type: 'number',
        },
        total_market_value: {
          label: 'Total Market Value',
          visibility: false,
          required: false,
          type: 'string',
        },
        status_of_landholding: {
          label: 'Landholding Status',
          visibility: false,
          required: false,
          type: 'string',
        },
        application_id: {
          label: 'Application Id',
          visibility: false,
          required: false,
          type: 'string',
        },
        report_date: {
          label: 'Report Date',
          visibility: false,
          required: false,
          type: 'string',
        },
        locality_of_property: {
          label: 'Locality Of Property',
          visibility: false,
          required: false,
          type: 'string',
        },
        state: {
          label: 'State',
          visibility: false,
          required: false,
          type: 'string',
        },
        pincode: {
          label: 'Pincode',
          visibility: false,
          required: false,
          type: 'number',
        },
        city: {
          label: 'City',
          visibility: false,
          required: false,
          type: 'string',
        },
        latitude: {
          label: 'Latitude',
          visibility: false,
          required: false,
          type: 'string',
        },
        longitude: {
          label: 'Longitude',
          visibility: false,
          required: false,
          type: 'string',
        },
        area_of_land: {
          label: 'Area Of Land',
          visibility: false,
          required: false,
          type: 'string',
        },

        rate_for_land_per_sft: {
          label: 'Rate For Land Per Sq. Ft.',
          visibility: false,
          required: false,
          type: 'number',
        },
        area_of_construction: {
          label: 'Area Of Construction',
          visibility: false,
          required: false,
          type: 'string',
        },
        rate_for_construction_per_sft: {
          label: 'Rate For Construction Per Sq. Ft.',
          visibility: false,
          required: false,
          type: 'number',
        },
        area_of_unit: {
          label: 'Area Of Unit',
          visibility: false,
          required: false,
          type: 'string',
        },
        rate_per_sft_for_unit: {
          label: 'Rate Per Sq. Ft. For Unit',
          visibility: false,
          required: false,
          type: 'string',
        },
        type_of_structure: {
          label: 'Type Of Structure',
          visibility: false,
          required: false,
          type: 'string',
        },
        landmark: {
          label: 'Landmark',
          visibility: false,
          required: false,
          type: 'string',
        },

        lineItemTable: {
          label: 'Line Item Table',
          visibility: true,
          required: false,
          type: 'string',
        },
      },

      show: false,
    },
    LoanApplication: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date',
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string',
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string',
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number',
        },

        doc_la_applicant_first_name: {
          label: 'Applicant First Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_la_applicant_middle_name: {
          label: 'Applicant Middle Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_la_applicant_last_name: {
          label: 'Applicant Last Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_la_applicant_date_of_birth: {
          label: 'Applicant Date of Birth',
          visibility: false,
          required: false,
          type: 'date',
        },
        doc_la_applicant_mobile_number: {
          label: 'Applicant Mobile Number',
          visibility: false,
          required: false,
          type: 'number',
        },
        doc_la_applicant_email: {
          label: 'Applicant Email',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_la_applicant_state_name: {
          label: 'Applicant State Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_la_applicant_city_name: {
          label: 'Applicant City Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_la_applicant_pincode_name: {
          label: 'Applicant Pincode Number',
          visibility: false,
          required: false,
          type: 'number',
        },
      },

      show: false,
    },
    depositoryorders: {
      fields: {},

      show: false,
    },

    tradeFinance: {
      fields: {},

      show: false,
    },
    autorejected: {
      fields: {
        doc_application_no_value: {
          label: 'Application Number',
          visibility: false,
          required: false,
          type: 'string',
        },
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_date_value: {
          label: 'Date',
          visibility: false,
          required: false,
          type: 'string',
        },
        reject_reason: {
          label: '',
          visibility: false,
          required: false,
          type: 'string',
        },
        doc_type_value: {
          label: 'Document Type',
          visibility: false,
          required: false,
          type: 'string',
        },
      },
      show: false,
    },
    importchecklist: {
      fields: {
        file_name: {
          label: 'File Name',
          visibility: false,
          required: false,
          type: 'string'
        },
        creation_date: {
          label: 'Creation Date',
          visibility: false,
          required: false,
          type: 'date'
        },
        approved_date: {
          label: 'Approved Date',
          visibility: false,
          required: false,
          type: 'date'
        },
        approved_by: {
          label: 'Approved By',
          visibility: false,
          required: false,
          type: 'string'
        },
        owner: {
          label: 'Owner',
          visibility: false,
          required: false,
          type: 'string'
        },
        accuracy: {
          label: 'Confidence Score',
          visibility: false,
          required: false,
          type: 'number'
        },

        doc_supplier_name_value: {
          label: 'Supplier Name',
          visibility: false,
          required: false,
          type: 'string'
        },
        doc_supplier_address_value: {
          label: 'Supplier Address',
          visibility: false,
          required: false,
          type: 'string'
        },
        doc_number_value: {
          label: 'Invoice Number',
          visibility: false,
          required: false,
          type: 'string'
        },
        doc_date_value: {
          label: 'Invoice Date',
          visibility: false,
          required: false,
          type: 'date'
        },
        doc_consignee_name_value: {
          label: 'Consignee Name',
          visibility: false,
          required: false,
          type: 'string'
        },
        doc_consignee_address_value: {
          label: 'Consignee Address',
          visibility: false,
          required: false,
          type: 'string'
        },
        doc_place_of_loading_value: {
          label: 'Port Of Loading',
          visibility: false,
          required: false,
          type: 'string'
        },
        doc_unit_weight_value: {
          label: 'Unit Weight',
          visibility: false,
          required: false,
          type: 'number'
        },
        doc_gross_weight_value: {
          label: 'Total Gross Weight',
          visibility: false,
          required: false,
          type: 'number'
        },
        doc_number_of_packages_value: {
          label: 'N° Of Packages',
          visibility: false,
          required: false,
          type: 'number'
        }, 
        doc_house_bill_of_lading_number_value: {
          label: 'HBL Number',
          visibility: false,
          required: false,
          type: 'string'
        },
        doc_house_bill_of_lading_date_value: {
          label: 'HBL Date',
          visibility: false,
          required: false,
          type: 'date'
        },
        doc_invoice_value: {
          label: 'Invoice Value',
          visibility: false,
          required: false,
          type: 'string'
        },
        doc_set_reference_number_value: {
          label: 'Set Reference Number',
          visibility: false,
          required: false,
          type: 'string'
        },

        lineItemTable: {
          label: 'Line Item Table',
          visibility: true,
          required: false,
          type: 'string',
        },
        dataItemTable: {
          label: 'Data Item Table',
          visibility: true,
          required: false,
          type: 'string',
        },

        
      },
      show: false,
    }
  },

  notification: {
    B2B_B2CTypes: {
      visibility: true,
      value: 'false',
    },
    saveChanges: {
      visibility: true,
      value: 'Yes',
    },
    downloadLocal: {
      visibility: true,
      value: 'Yes',
    },
    exportQuickbook: {
      visibility: true,
      value: 'Yes',
    },
    deleteInvoice: {
      visibility: true,
      value: 'Yes',
    },
    addTableRow: {
      visibility: true,
      value: 'Yes',
    },
    approveInvoice: {
      visibility: true,
      value: 'Yes',
    },
    deleteTableRow: {
      visibility: true,
      value: 'Yes',
    },
    deleteAllRows: {
      visibility: true,
      value: 'Yes',
    },
    saveCoOrdinates: {
      visibility: true,
      value: 'Yes',
    },
    markApproved: {
      visibility: true,
      value: 'Yes',
    },
    continuousHeaders: {
      visibility: true,
      value: 'Yes',
    },
    rejectPopup: {
      visibility: true,
      value: 'Yes',
    },
    reconciliationApproved: {
      visibility: true,
      value: 'Yes',
    },
  },

  tabs: [
    {
      name: 'Extraction',
      subtabs: [
        {
          allFields: {
            label: 'All Fields',
            visibility: false,
            required: false,
          },
          general: {
            label: 'General',
            visibility: true,
            required: false,
          },
          supplier: {
            label: 'Supplier',
            visibility: false,
            required: false,
          },
          customer: {
            label: 'Customer',
            visibility: false,
            required: false,
          },
          lessor: {
            label: 'Lessor',
            visibility: false,
            required: false,
          },
          shipment: {
            label: 'Shipment',
            visibility: false,
            required: false,
          },
          lessee: {
            label: 'Lessee',
            visibility: false,
            required: false,
          },
          tax: {
            label: 'Tax',
            visibility: false,
            required: false,
          },
          charges: {
            label: 'Charges',
            visibility: false,
            required: false,
          },
          two_wheeler: {
            label: 'Two Wheeler',
            visibility: false,
            required: false,
          },
          tables: {
            label: 'Tables',
            visibility: false,
            required: false,
          },
          misc: {
            label: 'Misc',
            visibility: false,
            required: false,
          },
          accounts: {
            label: 'Accounts',
            visibility: false,
            required: false,
          },
          farmInvoice: {
            label: 'Farm Invoice',
            visibility: false,
            required: false,
          },
          insurances: {
            label: 'Insurances',
            visibility: false,
            required: false,
          },
        },
      ],
      isOpen: true,
    },
    {
      name: 'Rules',
      subtabs: [
        {
          rule_results: {
            label: 'Rule Results',
            visibility: false,
            required: false,
          },
        },
      ],
      isOpen: false,
    },
    {
      name: 'Push API',
      subtabs: [
        {
          push_api: {
            label: 'Push API',
            visibility: false,
            required: false,
          },
        },
      ],
      isOpen: false,
    },
    {
      name: 'File Upload',
      subtabs: [
        {
          fileUpload_api_status: {
            label: 'File Upload API Status',
            visibility: false,
            required: false,
          },
        },
      ],
      isOpen: false,
    },
  ],

  // tabs: {
  //   general: {
  //     label: 'General',
  //     visibility: true,
  //     required: false,
  //   },
  //   supplier: {
  //     label: 'Supplier',
  //     visibility: false,
  //     required: false,
  //   },
  //   customer: {
  //     label: 'Customer',
  //     visibility: false,
  //     required: false,
  //   },
  //   lessor: {
  //     label: 'Lessor',
  //     visibility: false,
  //     required: false,
  //   },
  //   lessee: {
  //     label: 'Lessee',
  //     visibility: false,
  //     required: false,
  //   },
  //   tax: {
  //     label: 'Tax',
  //     visibility: false,
  //     required: false,
  //   },
  //   charges: {
  //     label: 'Charges',
  //     visibility: false,
  //     required: false,
  //   },
  //   two_wheeler: {
  //     label: 'Two Wheeler',
  //     visibility: false,
  //     required: false,
  //   },
  //   insurances: {
  //     label: 'Insurances',
  //     visibility: false,
  //     required: false,
  //   },
  //   push_api: {
  //     label: 'Push API',
  //     visibility: false,
  //     required: false,
  //   },
  //   fileUpload_api_status: {
  //     label: 'FileUpload API Status',
  //     visibility: false,
  //     required: false,
  //   },
  //   accounts: {
  //     label: 'Accounts',
  //     visibility: false,
  //     required: false,
  //   },
  //   tables: {
  //     label: 'Tables',
  //     visibility: false,
  //     required: false,
  //   },
  //   shipment: {
  //     label: 'Shipment',
  //     visibility: false,
  //     required: false,
  //   },
  //   misc: {
  //     label: 'Misc',
  //     visibility: false,
  //     required: false,
  //   },
  //   rule_results: {
  //     label: 'Rule Results',
  //     visibility: false,
  //     required: false,
  //   },
  //   reconciliation_result: {
  //     label: 'Reconciliation Result',
  //     visibility: false,
  //     required: false,
  //   },
  //   farmInvoice: {
  //     label: 'Farm Invoice',
  //     visibility: false,
  //     required: false,
  //   },
  //   allFields: {
  //     label: 'All Fields',
  //     visibility: false,
  //     required: false,
  //   },
  // },

  documentOCRConfidenceLevel: {
    high: 85,
    low: 40,
  },

  importFile: {
    maxLength: 3,
    minLength: 1,
  },

  systemFields: [
    {
      name: 'Invoice Number',
      value: 'invoice_number',
    },
    {
      name: 'Invoice Date',
      value: 'invoice_date',
    },
    {
      name: 'Supplier',
      value: 'supplier',
    },
    {
      name: 'Customer',
      value: 'customer',
    },
    {
      name: 'Supplier Code',
      value: 'supplier_code',
    },
    {
      name: 'Custom Field...',
      value: 'custome_field',
    },
  ],

  company_specific_params: {
    auto_classification_flag: false,
    translateLanguage: false,
    processing_enabled: false,
    duplicate_page_detection_flag: false,
    invoice_number_split_flag: true,
    read_QR_from_pdf_flag: true,
    textract_flag: true,
    vision_flag: false,
    vision_stable_flag: false,
    nuance_sdk_flag: false,
    delete_flag: true,
    rupee_flag: true,
    spacy_retraining_flag: true,
    date_validation_flag: false,
    cgst_end_flag: false,
    stamp_end_flag: true,
    header_standardize_flag: true,
    page_classification_flag: true,
    dense_flag: false,
    OCR_ENG_AUTO_SWITCH: false,
    is_tav_w2n: false,
    incremental_reconciliation_flag: false,
    str_through_flag: true,
    default_mobile_no_flag: false,
    base64_img_save_flag: true,
    description_without_itemcode: false,
    greybar_logic_flag: false,
    multiple_table_on_single_page_flag: false,
    table_detection: false,
    split_on_page_similarity_flag: true,
    auto_classification_of_table_headers: false,
    subcolumn_in_borderless_table_flag: false,
    release_version_in_api : false,


    total_value_limit: 24000000,
    active_session_limit: 5,
    bilingual_flag: {
      value: 0,
      options: [
        {
          displayName: 'Only English',
          value: 0,
        },
        {
          displayName: 'AR English',
          value: 1,
        },
        {
          displayName: 'Auto Detect',
          value: 2,
        },
      ],
    },

    ACCESS_ID: '',
    ACCESS_KEY: '',
    BUCKET_NAME: '',

    file_encryption_key: '',

    date_std: {
      value: 'ind',
      options: [
        {
          displayName: 'IND',
          value: 'ind',
        },
        {
          displayName: 'USA',
          value: 'usa',
        },
        {
          displayName: 'ZH',
          value: 'zh',
        },
      ],
    },

    page_auto_classification_input_list: {
      not_document_label_dict: {
        Invoices: [],
        PurchaseOrders: [],
        Receipts: [],
        Insurances: [],
        Marksheets: [],
        LoanNotices: [],
        RFQ: [],
        NACH: [],
        AddressDocument: [],
        IdCards: [],
        BolDocument: [],
        PackingList: [],
        BillofExchange: [],
        BADocument: [],
        TIFDocument: [],
        VSCCDocument: [],
        OnboardingForm: [],
        AirwayBill: [],
        DealerQuotation: [],
        Property_Appraisal: [],
        LoanApplication: [],
      },
      document_label_dict: {
        Invoices: [],
        PurchaseOrders: [],
        Receipts: [],
        Insurances: [],
        Marksheets: [],
        LoanNotices: [],
        RFQ: [],
        NACH: [],
        AddressDocument: [],
        IdCards: [],
        BolDocument: [],
        PackingList: [],
        BillofExchange: [],
        BADocument: [],
        TIFDocument: [],
        VSCCDocument: [],
        OnboardingForm: [],
        AirwayBill: [],
        DealerQuotation: [],
        Property_Appraisal: [],
        LoanApplication: [],
      },
      not_document_blacklist_string_dict: {
        Invoices: [],
        PurchaseOrders: [],
        Receipts: [],
        Insurances: [],
        Marksheets: [],
        LoanNotices: [],
        RFQ: [],
        NACH: [],
        AddressDocument: [],
        IdCards: [],
        BolDocument: [],
        PackingList: [],
        BillofExchange: [],
        BADocument: [],
        TIFDocument: [],
        VSCCDocument: [],
        OnboardingForm: [],
        AirwayBill: [],
        DealerQuotation: [],
        Property_Appraisal: [],
        LoanApplication: [],
      },
    },

    push_api_details: {
      "push_api_auth_token_url" : "",
      "push_api_auth_token_payload" : {
          "grant_type" : "",
          "client_id" : "",
          "client_secret" : "",
          "requested_subject" : ""
      },
      "push_api_auth_token_headers" : {
          "Content-Type" : "application/x-www-form-urlencoded"
      },
      "cookie_required" : false,
      "table_std_headers_required" : true,
      "table_std_headers_list" : [ 
          "table_index", 
          "Item Name", 
          "Description", 
          "Delivery Date", 
          "Service", 
          "Measure By", 
          "Unit Type", 
          "Quantity", 
          "Unit Rate", 
          "Taxable Value", 
          "Line_Item_Total", 
          "Tax Rate", 
          "Tax Amount", 
          "Secondary Tax Amount", 
          "Line_Item_Total", 
          "Discount Rate", 
          "Discount Amount", 
          "Witholding Tax", 
          "TDS Rate", 
          "TDS Amount"
      ],
      "custom_lineitem_field_mapping" : {
          "Test" : "custom_line_item2"
      },
      "custom_header_field_mapping" : {
          "Inv_Date_1" : "Custom_Field_1",
          "Inv_Total_1" : "Custom_Field_2"
      },
      "cookie_name" : "",
      "cookie_url" : "",
      "cookie_payload" : "",
      "cookie_headers" : {
          "Content-Type" : "application/json"
      },
      "push_api_url" : "",
      "push_api_headers" : {
          "Content-Type" : "application/json"
      },
      "max_retries":0
  }
  },

  subscription_details: {
    amount: 0,
    created_at: '',
    created_time: '',
    current_term_ends_at: '',
    current_term_starts_at: '',
    customer_id: '',
    expires_at: '',
    interval_unit: '',
    last_billing_at: '',
    plan_code: '',
    plan_name: '',
    plan_price: 0,
    plan_quantity: 0,
    email: '',
    start_date: '',
    status: '',

    subscription_id: '',
    updated_time: '',
  },

  field_confidence_score: {
    confidence_score: 0.85,
    field_confidence_score_flag: false,
  },

  payment_type: '',
  grace_limit: 0,
  grace_limit_expire_date: '',
  daily_exception_report_time: '',
  generic_heuristic: {},
  push_api_status: false,
  fileUpload_api_status: false,
  classIds: [],
  tallyCostCenterList: [],
  allowOthers: false,
  customFields: [],

  documentClasses: [],
  emailCollection: false,
  processing_pref: 'speed',

  free_trial_details: {
    free_trial_start_date: '',
    free_trial_end_date: '',
  },
  upload_limit: 0,
  upload_count: 0,

  document_detail_orientation_configuration: {
    layout_orientation: {
      value: 'right_handed',
      options: [
        {
          displayName: 'Left Handed',
          value: 'left_handed',
        },
        {
          displayName: 'Right Handed',
          value: 'right_handed',
        },
      ],
    },
    context_visibility: {
      value: true,
      options: [
        {
          displayName: 'On',
          value: true,
        },
        {
          displayName: 'Off',
          value: false,
        },
      ],
    },
  },

  release_version:'',
};
