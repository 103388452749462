import { Router, ActivatedRoute } from '@angular/router';
import { CompnayLogoTextDisplayService } from './../../../services/compnay-logo-text-display.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit, OnDestroy {

  public titleText = `${environment.companySpecificSettings.organizationName}: Page not found`;
  public isCompanyLogo = environment.companySpecificSettings.errorPageLogo;
  public logo_sm = environment.companySpecificSettings.logo_sm.url;
  public logo_md = environment.companySpecificSettings.logo_md.url;
  public logo_md_width = environment.companySpecificSettings.logo_md.width;

  constructor(
    private title: Title,
    public compnayLogoTextDisplayService: CompnayLogoTextDisplayService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.setPageTitle(this.titleText);
  }

  public setPageTitle(title: string): void {
    if (environment.companySpecificSettings.isCompanySpecificSettings) {
      this.title.setTitle(title);
    } else {
      let hostName = window.location.hostname;
      this.title.setTitle(hostName);
    }
  }



  ngOnDestroy(): void {

  }

}
