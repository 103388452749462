import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Dropdown01Component } from './dropdown01/dropdown01.component';
import { Dropdown02Component } from './dropdown02/dropdown02.component';
import { Dropdown03Component } from './dropdown03/dropdown03.component';
import { Dropdown04Component } from './dropdown04/dropdown04.component';
import { ClickoutsideDirective } from './directives/clickoutside.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Dropdown00Component } from './dropdown00/dropdown00.component';
import { Dropdown05Component } from './dropdown05/dropdown05.component';
import { Dropdown06Component } from './dropdown06/dropdown06.component';




@NgModule({
  declarations: [
    Dropdown00Component,
    Dropdown01Component,
    Dropdown02Component,
    Dropdown03Component,
    Dropdown04Component,
    ClickoutsideDirective,
    Dropdown05Component,
    Dropdown06Component,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    Dropdown00Component,
    Dropdown01Component,
    Dropdown02Component,
    Dropdown03Component,
    Dropdown04Component,
    Dropdown05Component,
    Dropdown06Component,
  ]
})
export class DropdownModule { }
