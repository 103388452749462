import { environment } from "src/environments/environment";


export class BaseApiUrl {

  // AuthenticationAPIs
  public static LoginURL = environment.AuthenticationAPIs.login;
  public static LogOutURL = environment.AuthenticationAPIs.logout;
  public static LoginGetAccessToken = environment.AuthenticationAPIs.getAccessToken;
  public static LoginGetAccessTokenfromRefreshToken = environment.AuthenticationAPIs.getAccessTokenfromRefreshToken;
  public static LoginGetAccessTokenfromUserId = environment.AuthenticationAPIs.getAccessTokenfromUserId;

  // FreeTrialUserAPIs
  public static RegisterFreeTrialUser = environment.FreeTrialUserAPIs.registerFreeTrialUser;

  // Business Email Valid UserAPIs
  public static IsValideBussinessEmail = environment.BusinessEmailValidAPIs.isValideBussinessEmail;

  // SubscriptionUserAPIs
  public static RegisterUserForSubscription = environment.SubscriptionUserAPIs.registerForSubscription;
  public static RegisterUserForCreditSubscription = environment.SubscriptionUserAPIs.registerForCreditSubscription;

  // UserAPIs
  public static GetUserDetails = environment.UserAPIs.getUserDetails;
  public static RegisterUserDetails = environment.UserAPIs.registerUserDetails;
  public static UpdateUserDetails = environment.UserAPIs.updateUserDetails;
  public static GetUserSettings = environment.UserAPIs.getUserSettings;
  public static UpdateUserSettings = environment.UserAPIs.insertUserSettings;

  public static CancelSubscription = environment.UserAPIs.cancelSubscription;
  public static UpgradeSubscription = environment.UserAPIs.upgradeSubscription;
  public static RenewSubscription = environment.UserAPIs.renewSubscription;

  //Credit User Details
  public static CreditUserDetails = environment.CreditUserDetailsAPIs.creditUserDetails;

  //Subscription User Details
  public static SubscriptionUserDetails = environment.UserAPIs.subscriptionForUserDetails;

  // ImportAccountAPIs
  public static UploadSupplierAccountList = environment.ImportAccountAPIs.uploadSupplierAccountList;
  public static AppendSupplierList = environment.ImportAccountAPIs.appendSupplierList;
  public static GetSupplierAccountList = environment.ImportAccountAPIs.getSupplierAccountList;
  public static GetChartAccountList = environment.ImportAccountAPIs.getChartAccountList;

  // SplitPdfAPIs
  public static SplitPDFFile = environment.SplitPdfAPIs.splitPdfFile;

  // InsightAPIs
  public static GetUserInsights = environment.InsightAPIs.getUserInsights;
  public static GetApprovedVsUploadedDocumentCount = environment.InsightAPIs.getApprovedVsUploadedDocumentCount;
  public static GetApprovedAgainstCumulativeUploadedDailyCount = environment.InsightAPIs.getApprovedAgainstCumulativeUploadedDailyCount;
  public static GetTotalAmountUploadedDailyCount = environment.InsightAPIs.getTotalAmountUploadedDailyCount;
  public static GetAccuracyVsDate = environment.InsightAPIs.getAccuracyVsDate;
  public static GetTopTenSuppliersVsDate = environment.InsightAPIs.getTopTenSuppliersVsDate;
  public static GetMonthlyPaymentDuePerSupplier = environment.InsightAPIs.getMonthlyPaymentDuePerSupplier;
  public static GetMonthlyPaymentOverDuePerSupplier = environment.InsightAPIs.getMonthlyPaymentOverDuePerSupplier;

  public static GetDailyDocumentCountAndPageCount = environment.InsightAPIs.getDailyDocumentCountAndPageCount;
  public static GetDailyDocumentCountAndAmountDueInDayRange = environment.InsightAPIs.getDailyDocumentCountAndAmountDueInDayRange;
  public static GetTopTenSuppliersByAmountiInDayRange = environment.InsightAPIs.getTopTenSuppliersByAmountiInDayRange;

  // DocumentsAPIs
  public static GetAllDocumentsCounts = environment.DocumentsAPIs.DocumentLists.getAllDocumentsCounts;
  public static GetAllDocumentsList = environment.DocumentsAPIs.DocumentLists.getAllDocumentsList;
  public static GetErroredDocumentsList = environment.DocumentsAPIs.DocumentLists.getErroredDocumentsList;
  public static GetapproveDocumentsList = environment.DocumentsAPIs.DocumentLists.getapproveDocumentsList;
  public static GetUnapproveDocumentsList = environment.DocumentsAPIs.DocumentLists.getUnapproveDocumentsList;
  public static GetQuickBookdocumentsList = environment.DocumentsAPIs.DocumentLists.getQuickBookDocumentsList;
  public static GetRuleErrorDocumentsList = environment.DocumentsAPIs.DocumentLists.getRuleErrorDocumentsList;
  public static GetAutoRejectedDocumentsList = environment.DocumentsAPIs.DocumentLists.getAutoRejectedDocumentsList;
  public static GetUserDocumentsList = environment.DocumentsAPIs.DocumentLists.getUserDocumentsList;
  public static GetRejectedDocumentsList = environment.DocumentsAPIs.DocumentLists.getRejectedDocumentsList;
  public static GetObjectIdOfAllDocumentsList = environment.DocumentsAPIs.DocumentLists.getObjectIdOfAllDocumentsList;

  public static GetDocumentsByApplicationNumberFilter = environment.DocumentsAPIs.DocumentLists.getDocumentsByApplicationNumberFilter;
  public static GetDocumentsBySupplierNameFilter = environment.DocumentsAPIs.DocumentLists.getDocumentsBySupplierNameFilter;
  public static GetDocumentsBymultipleParamsFilter = environment.DocumentsAPIs.DocumentLists.getDocumentsBymultipleParamsFilter;
  public static GetFilteredDocuments = environment.DocumentsAPIs.DocumentLists.getFilteredDocuments;

  public static AddStarredDocuments = environment.DocumentsAPIs.DocumentLists.addStarredDocuments;
  public static GetStarredDocuments = environment.DocumentsAPIs.DocumentLists.getStarredDocuments;

  public static UploadDocumentAsBinaryFile = environment.DocumentsAPIs.Upload.uploadDocumentAsBinaryFile;
  public static UploadDocumentsAsZipFile = environment.DocumentsAPIs.Upload.uploadDocumentsAsZipFile;
  public static RetryUploadDocument = environment.DocumentsAPIs.Upload.retryUploadDocument;
  public static GetUploadInProgressDocuments = environment.DocumentsAPIs.Upload.getUploadInProgressDocuments;
  public static GetDocumentsUploadingStatus = environment.DocumentsAPIs.Upload.getDocumentsUploadingStatus;
  public static DeleteAllDocumentsUploadStatus = environment.DocumentsAPIs.Upload.DeleteAllDocumentsUploadStatus;
  public static DeleteDocumentUploadStatusById = environment.DocumentsAPIs.Upload.deleteDocumentUploadedStatusById;

  public static DeleteDocumentsByIdsList = environment.DocumentsAPIs.Document.deleteDocumentsByIdsList;
  public static ApproveDocumentsByIdsList = environment.DocumentsAPIs.Document.approveDocumentsByIdsList;
  public static GetRuleApprovalByDocumentById = environment.DocumentsAPIs.Document.getRuleApprovalByDocumentById;


  public static GetDocumentById = environment.DocumentsAPIs.Document.getDocumentById;
  public static GetBase64ImageByImagePath = environment.DocumentsAPIs.Document.getBase64ImageByImagePath;
  public static DeleteDocumentById = environment.DocumentsAPIs.Document.deleteDocumentById;
  public static GetOriginalDocument = environment.DocumentsAPIs.Document.getOriginalDocument;
  public static GetTranslatedDocumentByLanguageAndId = environment.DocumentsAPIs.Document.getTranslatedDocumentByLanguageAndId;

  public static EligibleForRetraining = environment.DocumentsAPIs.Document.eligibleForRetraining;
  public static SaveDocumentDetails = environment.DocumentsAPIs.Document.saveDocumentDetails;
  public static UpdateDocumentDetails = environment.DocumentsAPIs.Document.updateDocumentDetails;
  public static UpdateBulkDocumentDetails = environment.DocumentsAPIs.Document.updateBulkDocumentDetails;

  public static GetOCRText = environment.DocumentsAPIs.OCR.getOCRText;
  public static GetOCRTableColumnText = environment.DocumentsAPIs.OCR.getOCRTableColumnText;
  public static GetOCRTableRowText = environment.DocumentsAPIs.OCR.getOCRTableRowText;
  public static GetOCRTableText = environment.DocumentsAPIs.OCR.getOCRTableText;

  public static ExportXMLFileById = environment.DocumentsAPIs.Document.exportXMLFileById;
  public static AddNewFieldDocumentTypeWise = environment.DocumentsAPIs.Document.addNewFieldDocumentTypeWise;
  public static UpdateNewFieldDocumentTypeWise = environment.DocumentsAPIs.Document.updateNewFieldDocumentTypeWise;
  public static GetRuleStatusByDocumentId = environment.DocumentsAPIs.Document.getRuleStatusByDocumentId;
  public static ApproveAllDocuments = environment.DocumentsAPIs.Document.approveAllDocuments;
  public static GetFieldsKeyMapping = environment.DocumentsAPIs.Document.getFieldsKeyMapping;
  public static ExportTalltyXMLFileById = environment.DocumentsAPIs.Document.exportTalltyXMLFileById;


  // RuleEngineAPIs
  public static AddRule = environment.RuleEngineAPIs.addRule;
  public static getRules = environment.RuleEngineAPIs.getRules;
  public static updateRule = environment.RuleEngineAPIs.updateRule;
  public static deleteRule = environment.RuleEngineAPIs.deleteRule;
  public static copyRule = environment.RuleEngineAPIs.copyRule;
  public static getRuleById = environment.RuleEngineAPIs.gerRuleById;
  public static executeReconciliationRuleById = environment.RuleEngineAPIs.executeReconciliationRuleById;
  public static viewReconciliationResultById = environment.RuleEngineAPIs.viewReconciliationResultById;
  public static DownloadReconciliationReport = environment.RuleEngineAPIs.downloadReconciliationReport;
  public static GetReconciliationRules = environment.RuleEngineAPIs.getReconciliationRules;
  public static ViewReconciliationReportSummaryById = environment.RuleEngineAPIs.viewReconciliationReportSummaryById;
  public static ApproveReconciliationDocumentsById = environment.RuleEngineAPIs.approveReconciliationDocumentsById;
  public static GetExecutionIdsOfReconciliationRule = environment.RuleEngineAPIs.getExecutionIdsOfReconciliationRule;
  public static GetReconciliationResultFromExecutionId = environment.RuleEngineAPIs.getReconciliationResultFromExecutionId;
  public static GetLineItemsForKnockOffAndExactMatchReconciliation = environment.RuleEngineAPIs.getLineItemsForKnockOffAndExactMatchReconciliation;

  // Audit ReportAPIs
  public static GetConsolidatedReconciliationResult = environment.AuditReportAPIs.getConsolidatedReconciliationResult;
  public static GetAllRuleNameListFromTargetDataSource = environment.AuditReportAPIs.getAllRuleNameListFromTargetDataSource;
  public static GetConsolidatedReconUserPreferences = environment.AuditReportAPIs.getConsolidatedReconUserPreferences;
  public static EditConsolidatedReconUserPreferences = environment.AuditReportAPIs.editConsolidatedReconUserPreferences;
  public static GetSourceAuditReport = environment.AuditReportAPIs.getSourceAuditReport;
  public static GetReconciliationResultById = environment.AuditReportAPIs.getReconciliationResultById;
  public static GetAuditReportData = environment.AuditReportAPIs.getAuditReportData;
  public static GetAuditReportCounts = environment.AuditReportAPIs.getAuditReportCounts;
  public static AuditApproveWithMultipleDocumentAndRule = environment.AuditReportAPIs.auditApproveWithMultipleDocumentAndRule;
  public static DownloadAuditAReport = environment.AuditReportAPIs.downloadAuditAReport;
  public static GetReconciliationRuleNames = environment.AuditReportAPIs.getReconciliationRuleNames;
  public static InstantReconciliation = environment.AuditReportAPIs.instantReconciliation;
  public static SendAuditReportOverMail = environment.AuditReportAPIs.sendAuditReportOverMail;
  public static RemoveReconApproval = environment.AuditReportAPIs.removeReconApproval;
  public static InstantReconciliationWithMultiDocument = environment.AuditReportAPIs.instantReconciliationWithMultiDocument;
  // DataSourceAPIs
  public static AddDataSource = environment.DataSourceAPIs.insertDataSource;
  public static GetDataSources = environment.DataSourceAPIs.getDataSources;
  public static GetDataSourceByName = environment.DataSourceAPIs.getDataSourceByName;
  public static DeleteDataSourceByName = environment.DataSourceAPIs.deleteDataSource;
  public static AppendDataSourceByName = environment.DataSourceAPIs.appendDataSource;
  public static FilterDataSource = environment.DataSourceAPIs.filterDataSource;
  public static AppendDataSourceWithSingleRecord = environment.DataSourceAPIs.appendDataSourceWithSingleRecord;
  public static EditAndDeleteSelectedRecordInDataSource = environment.DataSourceAPIs.editAndDeleteSelectedRecordInDataSource;


  // PaymentAPIs
  public static PaymentScheduler = environment.PaymentAPIs.paymentScheduler;

  // Pretrained Document Model
  public static AddCustomFieldToThePretrainedDocumentModel = environment.PretrainedDocumentModel.addCustomField;
  public static deleteCustomFieldFromThePretrainedDocumentModel = environment.PretrainedDocumentModel.deleteCustomField;
  public static editCustomFieldFromThePretrainedDocumentModel = environment.PretrainedDocumentModel.editCustomField;
  public static GetTrainingAndTestingDocuments = environment.PretrainedDocumentModel.getTrainingAndTestingDocuments;
  public static CompleteTrainingDocuments = environment.PretrainedDocumentModel.completeTrainingDocuments;

  // Custom Document Model


}
